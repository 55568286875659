/// <reference types="cordova" />
/// <reference types="cordova-plugin-file" />

import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {enableProdMode} from '@angular/core';
import {environment} from './environments/environment';
import * as PouchDB from 'pouchdb-browser';
import {merge} from 'lodash';
import {Settings} from 'luxon';
import * as Sentry from '@sentry/browser';

// save the pouchdb globally so the pouchdb chrome extension can work
(<any>window).PouchDB = (<any>PouchDB).default;

// this is only used for unit test detection
Settings.defaultLocale = 'de-CH';
const httpRequest = new XMLHttpRequest();
httpRequest.onreadystatechange = function () {
  if (httpRequest.readyState === XMLHttpRequest.DONE) {
    if (httpRequest.status === 200) {
      const environmentSettings = JSON.parse(httpRequest.responseText);
      merge(environment, environmentSettings);
      if (environment.production) {
        enableProdMode();
      }
      const start = () => {
        if (environment.trackErrors) {
          Sentry.init({
            dsn: 'https://e1fd47e00cfb48cc9c25ac9bde4f197d@sentry.io/1834707',
            environment: environment.envName,
          });
        }
        import('./app/app.module').then((m) => {
          platformBrowserDynamic()
            .bootstrapModule(m.AppModule)
            .catch((err) => console.log(err));
        });
      };
      if ((<any>window).cordova) {
        document.addEventListener('deviceready', function () {
          start();
        }, false);
      } else {
        start();
      }
    } else {
      console.error('there was a problem with loading the environment settings');
    }
  }
};
httpRequest.open('GET', 'assets/settings.json');
httpRequest.send();
