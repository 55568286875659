// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: true,
  envName: 'test',
  version: '0.0.0',
  trackErrors: true,
  appDistributionUrl: 'https://version.connect.wwag.ch',
  shouldPollForUpdates: true,
  data: {
    defaultVariantNumber: 0,
  },
  config: {
    client: {
      storableList: {
        useTags: false,
        useStorages: false,
        showStorableIdTags: false,
      },
      transactionList: {
        resultsFetchedByAutocomplete: 5,
      },
      storableEdit: {
        allowPriceEditing: true,
      },
      sync: {
        push: {
          batchSize: 1000,
          timeout: 60000,
        },
        pull: {
          batchSize: 1000,
          timeout: 60000,
        },
      },
      showQrScanButton: false,
    },
    server: {
      dispoApi: 'https://connect-dev.wwag.ch/Web',
      warehouseApi: 'https://wh-server.connect-dev.wwag.ch',
      auth: {
        server: 'https://auth.connect-dev.wwag.ch',
        clientId: 'wwag-wh',
        clientSecret: 'E53881F9-EB80-482D-ABCB-37379CE28B6A',
        scopes: 'openid profile offline_access wwag-wh wwag-dispo user_api'
      },
      authWhitelist: ['auth.connect-dev.wwag.ch', 'auth.connect.wwag.ch', 'version.connect.wwag.ch'],
    },
  },
  development: {
    disableSyncOnStartup: false
  }
};
